import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/Seo';

const useStyles = createUseStyles(() => ({
  iframe: {
    minHeight: '640px',
    width: '100%',
  },
}));

const Location = (props) => {
  const classes = useStyles(props);
  return (
    <Layout>
      <SEO title="Location" />
      <iframe
        className={classes.iframe}
        src="https://www.google.com/maps/d/embed?mid=zAzadkHwn238.k9Lhp8MQSqco"
        title="Location Map"
      />
    </Layout>
  );
};

export default Location;
